import { requireValue } from "modules/picasso-modules/utils/validateUtils";

export const getFieldByPathV2 = (o: any, s: string) => {
    requireValue(o)
    requireValue(s)

    try {
        if (!s.includes('.')) {
            return o[s];
        }
    
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            
            if (o == null) {
                return null;
            }

            if (o.isGroup === true) {
                const groupRes = []
                for (const filteredArrItem of o.obj) {
                    console.log(filteredArrItem, 'k', k)
                    groupRes.push(getFieldByPathV2(filteredArrItem, k))
                }
                console.log('groupRes', groupRes)
                o = { isGroup: true, obj: groupRes }
                continue;
            }

            if (k.includes('[')) {
                console.log('a')
                const [prop, condition] = k.split('=');
                const propName = prop.substring(1, prop.length);
                const conditionValue = condition.slice(0, -1);

                const arr = o;

                console.log(prop, 'propName', propName)

                if (Array.isArray(arr)) {
                    const filteredArr = arr.filter((item: any) => {
                        const [key, value] = Object.entries(item)[0];
                        console.log(key, propName, value, conditionValue)
                        return key === propName && value === conditionValue;
                    });
                    console.log('filteredArr', filteredArr)
                    if (filteredArr.length > 0) {
                        o = { isGroup: true, obj: filteredArr }
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }
            } else {
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
        }
        return o?.isGroup ? o.obj : o
    } catch (error) {
        throw error;
    }
}

    
/* console.log('nothing', getFieldByPathV2(currentData, 'nothing'))
console.log('userId', getFieldByPathV2(currentData, 'userId'))
console.log('messageTemplates', getFieldByPathV2(currentData, 'messageTemplates'))
console.log('abc.nested', getFieldByPathV2({abc: {nested: 'test'}}, 'abc.nested'))
console.log('abc', getFieldByPathV2({abc: {nested: 'test'}}, 'abc')) */


export const setFieldByPathV2 = (obj: any, path: string, value: any) => {
    requireValue(obj);
    requireValue(path);
    
    const [head, ...rest] = path.split('.');

    if (!rest.length) {
        obj[head] = value;
        return obj;
    } else {
        if (!obj[head]) {
            obj[head] = {};
        } else if (Array.isArray(obj[head]) && rest[0].includes('[')) {
            const [prop, condition] = rest[0].slice(1, -1).split('=');
            const conditionValue = condition.slice(0, -1);
            const arr = obj[head];
            const filteredArr = arr.filter((item: any) => {
                const [key, val] = Object.entries(item)[0];
                return key === prop && val === conditionValue;
            });
            if (filteredArr.length > 1) {
                throw new Error('Multiple array items match the condition');
            } else if (filteredArr.length === 1) {
                setFieldByPathV2(filteredArr[0], rest.slice(1).join('.'), value);
            } else {
                throw new Error('No array item matches the condition');
            }
        } else {
            setFieldByPathV2(obj[head], rest.join('.'), value);
        }
        return obj;
    }
}