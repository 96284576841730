import { useState } from 'react';
import { createFormRecord } from 'utils/formsApi';
import alerts from 'modules/yoio/state/alerts';
import { isHavingValue } from 'utils/objectUtils';
import { removeArrayItem } from 'utils/arrayUtils';
import { getWindowLocationHrefSafe } from 'utils/windowUtils';
import { useRouter } from 'next/router';
import { FormDef } from './FormTypes';
import { requireValue } from 'modules/picasso-modules/utils/validateUtils';
import { buildAbsoluteUrlWithCurrentHost, buildUrlWithParameterAppended, isAbsoluteUrl } from 'utils/urlUtils';
import { notify } from 'modules/yoio/errorsService';
import { getFieldByPathV2 } from 'utils/objectAccessUtils';

/**
 * submit generic form records
 */

export interface UseFormSubmitProps {
    form: FormDef
    formRecordId?: string
    onSuccess?: (responseData: any) => Promise<any>
    onError?: (data?: any)=>void
    initialData?: any
}

export const useFormSubmit = ({form, formRecordId, onSuccess, onError, initialData}:UseFormSubmitProps) => {

    const router = useRouter()

    const [createdAtMs] = useState(Date.now())

    const [progress, setProgress] = useState<boolean>()

    const getSecondsSinceCreate = () => {
        return Math.round((Date.now() - createdAtMs) / 1000);
    }

    const submitForm = async (data:object&{metaLocation?: string}, dontContinueToAfterSubmitUrl?:boolean, dirtyFields?: string[]) => {
        if (!form.formId) {
            console.error('unexpected error - no form id');
            return;
        }

        setProgress(true)

        // Is update
        if (formRecordId) {

            const updates = []

            for (const dirtyField of dirtyFields) {
                const initialValue = getFieldByPathV2(initialData, dirtyField);
                const currentValue = getFieldByPathV2(data, dirtyField);
        
                const patchPropertyPath = dirtyField.replaceAll('.', '/')

                if (Array.isArray(initialValue) && Array.isArray(currentValue)) {
                    // Handle array differences
                    const itemsToAdd = currentValue.filter(item => !initialValue.includes(item));
                    const itemsToRemove = initialValue.filter(item => !currentValue.includes(item));
        
                    for (const item of itemsToAdd) {
                        updates.push({ op: 'add', path: `${patchPropertyPath}/-`, value: item });
                    }
        
                    for (const item of itemsToRemove) {
                        updates.push({ op: 'remove', path: `${patchPropertyPath}/-`, value: item });
                    }
                } else {
                    // Regular field update
                    updates.push({ op: 'replace', path: dirtyField, value: currentValue });
                }
            }

            console.log('updates', updates)
            console.log('data', data)
        }

        const errors = [];

        if (isJustOneDataFieldAndEmpty(data)) {
            errors.push('Field cannot be empty')
        }

        if (errors.length > 0) {
            alerts.error(errors[0]);
            if (onError) {
                onError(data)
            }
            return;
        }

        if (form.isProtectionEnabled && getSecondsSinceCreate() <= 3) {
            //Spam Protection
            alerts.error('Unexpected error. Please try again.');
            if (onError) {
                onError(data)
            }
            return;
        }

        data.metaLocation = getWindowLocationHrefSafe() //its just about the "ref" query param

        const onAfterSuccess = (recordId:string) => {
            if (form.afterSubmitUrl && dontContinueToAfterSubmitUrl !== true) {

                let urlEffective = form.afterSubmitUrl+'';

                try {
                    if (form.afterSubmitUrlParameterFormRecordId !== undefined && form.afterSubmitUrlParameterFormRecordId !== null) {
                        const formRecordId = `forms/${requireValue(form.formId)}/records/${requireValue(recordId)}`

                        if (!isAbsoluteUrl(urlEffective)) {
                            urlEffective = buildAbsoluteUrlWithCurrentHost(urlEffective)
                        }

                        urlEffective = buildUrlWithParameterAppended(urlEffective, form.afterSubmitUrlParameterFormRecordId, formRecordId)
                    }
                } catch (error) {
                    notify(error)
                    notify(new Error(`failed to generate urlEffective with formRecordId. proceeding with the original url. formRecordId:${recordId}`))
                    urlEffective = form.afterSubmitUrl
                }

                router.push(urlEffective)
            } else {
                setProgress(false)
            }
        }

        if (formRecordId) {
            // WIP
            console.log(`formRecordId`, formRecordId, data)
            return Promise.resolve()
        } else {
            return createFormRecord(form.formId, data).then((res)=>{
                if (!form.afterSubmitUrl) {
                    alerts.success('Submitted.');
                }
    
                if (onSuccess) {
                    onSuccess(res.data).then(()=>{
                        onAfterSuccess(res.data.formRecordId)
                    })
                } else {
                    onAfterSuccess(res.data.formRecordId)
                }
            }).catch((e) => {
                alerts.error('Unexpected error. Please try again.');
                if (onError) {
                    onError(data)
                }
                setProgress(false)
            });
        }


    };

    return { submitForm, progress, forwarding: progress && isHavingValue(form.afterSubmitUrl) }


}

const isJustOneDataFieldAndEmpty = (data) => {
    const realDataKeys = Object.keys(data);
    removeArrayItem(realDataKeys, 'submit')
    removeArrayItem(realDataKeys, 'pageOrigin')

    let isError = false;

    //validation
    if (realDataKeys.length === 1) {
        //just one field - check if it is empty
        realDataKeys.forEach((v)=>{
            const value = data[v];
            if (!isHavingValue(value) || value.length === 0) {
                isError = true;
            }
        })
    }

    return isError;
}