import dynamic from 'next/dynamic';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme:Theme)=>({
    rootV1: {
        fontFamily: theme.typography.fontFamily,
        '& textarea': {
            fontFamily: theme.typography.fontFamily,
        },
        '& button': {
            backgroundColor: theme.palette.primary.main + ' !important',
            border: 'none !important',
            color: theme.palette.primary.contrastText,
            height: '40px !important',
            fontWeight: '500',
            //paddingLeft: theme.spacing(6),
            //paddingRight: theme.spacing(6),
            fontFamily: theme.typography.fontFamily + ' !important',
            '&:active,&:focus,&:hover': {
                border: 'none !important',
                boxShadow: 'none !important'
            }
        },
        '& button:disabled': {
            backgroundColor: theme.palette.action.disabledBackground + ' !important',
            border: 'none !important',
            color: theme.palette.action.disabled
        }

    },
    rootV2: {
        '& button.btn-primary': {
            backgroundColor: theme.palette.primary.main,
            border: 'none',
            color: theme.palette.primary.contrastText,
            fontWeight: '500',
            fontFamily: theme.typography.fontFamily,
        }
    },
    rootSubmitButtonFullWidth: {
        '& button[type=submit]': {
            width: '100%'
        }
    },
    rootSubmitButtonIncentiveStyle: {
        '& button[type=submit]': {
            borderRadius: '65px 65px 65px 65px'
        }
    },
    rootInputIncentiveStyle: {
        '& input[type=text], & input[type=email]': {
            borderRadius: '65px 65px 65px 65px',
            border: 'none !important',
            boxShadow: 'none !important',
            '&:active,&:focus,&:hover': {
                border: 'none !important',
                boxShadow: 'none !important'
            }
        },
    }
}));

const FormInternal = dynamic(
    () => import('./FormInternal'),
    { ssr:
 false }
  )

export const FormSimple = ({ form, initialData, logLevel, formRecordId }:{form: any, initialData?: any, logLevel?: string, formRecordId?: string }) => {

    const classes = useStyles();

    form.stylingVariant=form.stylingVariant??'v1'

    let classNames = [form.stylingVariant==='v1'?classes.rootV1:null, form.stylingVariant==='v2'?classes.rootV2:null]

    if (form.styling?.submitButtonFullWidth === true) {
        classNames.push(classes.rootSubmitButtonFullWidth);
    }
    if (form.styling?.submitButtonIncentiveStyle === true) {
        classNames.push(classes.rootSubmitButtonIncentiveStyle);
    }
    if (form.styling?.inputIncentiveStyle === true) {
        classNames.push(classes.rootInputIncentiveStyle);
    }

    return (
        <div className={classNames.filter(c=>c).join(' ')}>
            <FormInternal form={form} initialData={initialData} logLevel={logLevel} formRecordId={formRecordId} />
        </div>
    )

}